// ** Initial State
const initialState = {
    statistics: [],
    forwards: [],
    meetingRooms: [],
    integrations: [],
    configure: [],
    cname: [],
    headers: {},
    selectedOrganizationId: null,
    vmeetConfigure: [],
    selectedUserId: null
}

const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STATISTICS':
            return {
                ...state,
                statistics: action.data
            }
        case 'GET_STATISTICS_EXPORT':
            return {
                ...state,
                statisticsExport: action.data
            }
        case 'GET_FORWARDS':
            return {
                ...state,
                forwards: action.data
            }
        case 'GET_MEETING_ROOMS':
            return {
                ...state,
                meetingRooms: action.data,
                headers: action.headers
            }
        case 'GET_INTEGRATIONS':
            return {
                ...state,
                integrations: action.data
            }
        case 'GET_CONFIGURE':
            return {
                ...state,
                configure: action.data
            }
        case 'GET_VMEET_CONFIGURE':
            return {
                ...state,
                vmeetConfigure: action.data
            }
        case 'GET_CNAME':
            return {
                ...state,
                cname: action.data,
                headers: action.headers
            }
        case 'GET_MEETING_ROOMS_EXPORT':
            return {
                ...state,
                meetingRoomsExport: action.data
            }
        case 'SET_SELECTED_ORGANIZATION_ID':
            return {
                ...state,
                selectedOrganizationId: action.data
            }
        case 'SET_SELECTED_USER_ID':
            return {
                ...state,
                selectedUserId: action.data
            }

        default:
            return state
    }
}

export default organizationReducer

