// ** Initial State
const initialState = {
    history: null,
    buttonViews: null,
    trackerViews: null,
    dataLoading: true,
    dataError: false
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_WELCOME_ITEMS':
            return {
                history: action.data.history,
                buttonViews: action.data.buttonViews,
                trackerViews: action.data.trackerClicks,
                dataLoading: false,
                dataError: false
            }
        case 'GET_WELCOME_ITEMS_ERROR':
            return {
                history: null,
                buttonViews: null,
                trackerViews: null,
                dataError: true,
                dataLoading: false
            }
        default:
            return state
    }
}

export default homeReducer
