/* global AUTHORIZATION */
/* global IISIGHT_BASE_URL */

import axios from 'axios'

export const getApiBaseUrl = () => {
  return window?.location?.hostname === 'localhost' ? 'http://13.200.43.239:7445/' : 'http://13.200.43.239:7445/'
  // return window?.location?.hostname === 'localhost' ? 'http://127.0.0.1:7445/' : 'http://127.0.0.1:7445/'
}

axios.defaults.baseURL = getApiBaseUrl()
axios.defaults.headers = {
  'Content-Type': 'application/json',
}